import "./App.css";
import { NavBar } from "./NavBar";
import { MobileNavBar } from "./MobileNavBar";
import { GiHamburgerMenu } from "react-icons/gi";
import { Name } from "./Name";
import { Home } from "./Home";
import { AboutMe } from "./AboutMe";
import { Projects } from "./Projects";

function App() {
  const toggleHidden = () => {
    const element = document.getElementById("mobile-nav");
    element && element.classList.toggle("hidden");
  };

  return (
    <div className="app">
      <header className="header">
        <Name />
        <NavBar />
      </header>
      <header className="mobile-header">
        <Name />
        <GiHamburgerMenu onClick={toggleHidden} />
        <MobileNavBar clickHandler={toggleHidden} />
      </header>
      <Home />
      <AboutMe />
      <Projects />
    </div>
  );
}

export default App;
