import React from "react";
import { BsGithub } from "react-icons/bs";

export function Projects() {
  const projects = [
    {
      name: "Java Cement Lab App",
      href: "#",
      src: "images/CementLabApp.png",
      alt: "Cement Lab App",
      skills: ["Java", "Swing", "MySQL"],
      github: "https://github.com/Daaargh/Java-Cement-Lab-App",
    },

    {
      name: "Legion Game",
      href: "https://legion-game.netlify.app",
      src: "images/Legion.png",
      alt: "Legion Dice Game",
      skills: ["HTML", "CSS", "JS", "React"],
      github: "https://github.com/Daaargh/legion",
    },
    {
      name: "Guitar Tab App",
      href: "#",
      src: "images/guitarApp.png",
      alt: "Guitar Tab App",
      skills: ["C#", "XAML", "WPF"],
      github: "https://github.com/Daaargh/GuitarApp",
    },
    {
      name: "Landmark AI App",
      href: "#",
      src: "images/LandMarkAI.png",
      alt: "Landmark AI App",
      skills: ["C#", "XAML", "WPF", "Azure"],
      github: "https://github.com/Daaargh/LandmarkAI",
    },
    {
      name: "Weather App",
      href: "#",
      src: "images/weatherApp.png",
      alt: "Weather App",
      skills: ["C#", "XAML", "WPF"],
      github: "https://github.com/Daaargh/WeatherApp",
    },
    {
      name: "Contacts App",
      href: "#",
      src: "images/contactsApp.png",
      alt: "Contacts App",
      skills: ["C#", "XAML", "WPF", "SQLite"],
      github: "https://github.com/Daaargh/DesktopContactsApp",
    },
  ];

  return (
    <div className="projects" id="projects">
      <h2>PROJECTS</h2>
      <div className="app__container">
        {projects.map((project) => (
          <div key={project.name} className="project-app">
            <h3>{project.name}</h3>
            <a href={project.github}>
              <img
                className="app-img"
                src={project.src}
                alt={project.alt}
              ></img>
            </a>
            <div className="caption">
              <div className="project-skills">
                {project.skills.map((skill) => {
                  return <div className="about-me__skill">{skill}</div>;
                })}
                <a href={project.github}>
                  <BsGithub className="icon github-icon" />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
