import React from "react";

export function Home() {
  return (
    <div className="home">
      <div className="home__content">
        <h1 className="home__header">Hi, my name is Paul Anderson</h1>
        <p className="home__para">
          I am seeking my first job in development as either a full-stack or
          front-end developer
        </p>
        <a href="#projects">
          <button className="home__btn" type="button">
            PROJECTS
          </button>
        </a>
      </div>
    </div>
  );
}
