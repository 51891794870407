import { BsLinkedin, BsGithub } from "react-icons/bs";

export function AboutMe() {
  const skills = [
    "HTML",
    "CSS",
    "JavaScript",
    "TypeScript",
    "React",
    "C#",
    ".Net",
    "Java",
  ];
  return (
    <section id="about-me" className="about-me">
      <div className="about-me__container">
        <h2>ABOUT ME</h2>
        <div className="about-me__content">
          <div className="about-me__text">
            <h3>BACKGROUND</h3>
            <p>
              I studied a BSc(Hons) in Computing and IT (Software) at the Open
              University which I completed in Dec 2020 achieving an Upper
              Second-Class honours (2.1)
              <br />
              <br />
              <br />
              Since completing my studies, I have been working to expand my
              development knowledge, with a particular focus on JS/TS, React and
              C# (.Net). I am seeking a role in either software or
              front-end/full-stack web development.
            </p>
            <br />
            <a href="https://www.linkedin.com/in/paul-anderson-dev">
              <BsLinkedin className="icon linkedin-icon" />
            </a>
            <a href="https://www.github.com/Daaargh">
              <BsGithub className="icon github-icon" />
            </a>
          </div>
          <div className="about-me__skills">
            <h3>SKILLS</h3>
            <div className="skills">
              {skills.map((skill) => (
                <div className="about-me__skill">{skill}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
