interface MobileNavBarProps {
  clickHandler: () => void;
}

export const MobileNavBar = ({ clickHandler }: MobileNavBarProps) => {
  const links = [
    {
      linkName: "HOME",
      href: "#",
    },
    {
      linkName: "ABOUT ME",
      href: "#about-me",
    },
    {
      section: document.querySelector("#projects"),

      linkName: "PROJECTS",
      href: "#projects",
    },
    {
      linkName: "GAME MUSIC",
      href: "#game-music",
    },
    {
      linkName: "CONTACT",
      href: "#game-music",
    },
  ];

  return (
    <nav className="hidden mobile-nav" id="mobile-nav">
      <div className="link-container" id="link-container">
        <ul className="mobile-nav__links">
          {links.map((link) => (
            <li key={link.linkName}>
              <a
                onClick={clickHandler}
                className="mobile-nav__link"
                href={link.href}
              >
                {link.linkName}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};
