export function NavBar() {
  const links = [
    {
      linkName: "HOME",
      href: "#",
    },
    {
      linkName: "ABOUT ME",
      href: "#about-me",
    },
    {
      linkName: "PROJECTS",
      href: "#projects",
    },
    {
      linkName: "GAME MUSIC",
      href: "#game-music",
    },
    {
      linkName: "CONTACT",
      href: "#game-music",
    },
  ];

  return (
    <nav className="nav">
      <ul className="nav__links">
        {links.map((link) => (
          <li key={link.linkName}>
            <a href={link.href} className="nav__link">
              {link.linkName}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
